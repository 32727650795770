import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import {
  addFirstPartyCookieParamsToUrl,
  getFirstPartyCookieValues,
} from '@/lib/first-party-cookie';
import useBreakpoint from '@/lib/hooks/use-breakpoint';
import { useStoreDispatch } from '@/store';
import {
  selectPlayerCurrentChannel,
  selectPlayerIsLoading,
  selectPlayerIsMuted,
  selectPlayerIsPlaying,
  selectPlayerLibHasLoaded,
  selectPlayerVolume,
  setCurrentAudio,
  setIsLoading,
  setIsPlaying,
  setMetadata,
} from '@/store/slices/player';
import { Channel } from '@/types/views/generic';

import getPlayerMetadataFromIcyMetadata from '../get-player-metadata-from-icy-metadata';
import getPlayoutById from '../get-playout-by-id';

let playerInstance: IcecastMetadataPlayer | null = null;
let currentChannelId: Channel['id'] | null = null;
let oldChannelId: Channel['id'] | null = null;

const getPlayer = (
  libLoaded: boolean,
  channel: Channel | null,
  isPlayingOrLoading: boolean,
  dispatch: ReturnType<typeof useStoreDispatch>,
  isMobile: boolean,
) => {
  if (!libLoaded) {
    return null;
  }

  if (
    typeof window !== 'undefined' &&
    channel &&
    (channel.id !== currentChannelId || !playerInstance)
  ) {
    const wasPlayingOrLoading = isPlayingOrLoading;

    const streamUrl = new URL(channel.streamUrl);

    const cookieData = getFirstPartyCookieValues();

    if (cookieData) {
      addFirstPartyCookieParamsToUrl(streamUrl, cookieData);
    }

    streamUrl.searchParams.append(
      'ua',
      `energy+website+${isMobile ? 'mobile' : 'desktop'}`,
    );

    playerInstance?.stop();

    playerInstance = new IcecastMetadataPlayer(streamUrl.toString(), {
      onMetadata: async (icyMetadata) => {
        if (!icyMetadata?.StreamUrl) {
          return;
        }

        const streamMetadata = getPlayerMetadataFromIcyMetadata(icyMetadata);

        if (streamMetadata) dispatch(setMetadata(streamMetadata));

        const { searchParams } = new URL(icyMetadata.StreamUrl);
        const playoutIdString = searchParams.get('plId');

        if (!playoutIdString) {
          return;
        }

        const playoutId = parseInt(playoutIdString, 10);

        try {
          const playout = await getPlayoutById(playoutId, channel.id);

          if (playout?.song) {
            dispatch(
              setMetadata({
                artist: playout.song.artists_full,
                artists: playout.song.artists.map(({ id, name }) => ({
                  id,
                  name,
                })),
                title: playout.song.title,
                duration: playout.song.duration,
                coverUrl: playout.resolved_cover_url,
              }),
            );
          } else {
            // TODO: ECH-3457
            console.warn('Could not get metadata from BM.');
          }
        } catch (error) {
          // TODO: ECH-3457
          console.error(error);
          console.error('AWS client sent error');
        }
      },
      onPlay: () => {
        // first play event after page load
        if (!oldChannelId) {
          window.dataLayer?.push({
            event: 'first_player_start',
            current_channel_id: currentChannelId,
          });
        }

        // Channel Changed Event (PlayerState: Running || Paused)
        if (currentChannelId !== oldChannelId && oldChannelId) {
          window.dataLayer?.push({
            event: 'audio_stream_changed',
            current_channel_id: currentChannelId,
            old_channel_id: oldChannelId,
          });
        }

        dispatch(setIsPlaying(true));
        dispatch(setIsLoading(false));
        // reset audio
        dispatch(setCurrentAudio(null));
      },
      onStop: () => {
        // only sent the pause event, if a channel was stopped, not because the channel was switched during player state: running
        if (currentChannelId === channel.id) {
          window.dataLayer?.push({
            event: 'player_pause',
            current_channel_id: currentChannelId,
          });
        }

        dispatch(setIsPlaying(false));
        dispatch(setMetadata(null));
        oldChannelId = channel.id;
      },
      onLoad: () => {
        dispatch(setIsLoading(true));
      },
    });
    currentChannelId = channel.id;

    if (wasPlayingOrLoading) {
      playerInstance.play();
    }
  }

  return playerInstance;
};

export const usePlayer = () => {
  const dispatch = useStoreDispatch();

  const playerLibHasLoaded = useSelector(selectPlayerLibHasLoaded);
  const currentChannel = useSelector(selectPlayerCurrentChannel);
  const isPlaying = useSelector(selectPlayerIsPlaying);
  const isLoading = useSelector(selectPlayerIsLoading);
  const { isMobile } = useBreakpoint();

  const player = useMemo(
    () =>
      getPlayer(
        playerLibHasLoaded,
        currentChannel,
        isPlaying || isLoading,
        dispatch,
        isMobile,
      ),

    // we do not want to replace the player if isPlaying changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, playerLibHasLoaded, currentChannel, isMobile],
  );

  const volume = useSelector(selectPlayerVolume);
  const isMuted = useSelector(selectPlayerIsMuted);

  useEffect(() => {
    const audioElement = player?.audioElement;

    if (audioElement) {
      audioElement.volume = isMuted ? 0 : volume / 100;
      audioElement.muted = isMuted;
    }
  }, [player, volume, isMuted]);

  return player;
};

export default usePlayer;
