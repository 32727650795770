import differenceInYears from 'date-fns/differenceInYears';
import parse from 'date-fns/parse';
import { parseCookies } from 'nookies';

export interface CookieData {
  gender: string | undefined;
  age: string | undefined;
  plz: string | undefined;
  playerId: string | undefined;
  audioRaffle: AudioRaffle | undefined;
}

type ageRangeAlias = 'y0' | 'y1' | 'y2' | 'y3' | 'y4' | 'y5';

type genderAlias = 'x1' | 'x2' | 'x3';

export enum AudioRaffle {
  Active = 'rt',
  Inactive = 'rf',
}

export interface CookieType {
  x: genderAlias | undefined;
  y: ageRangeAlias | undefined;
  l: string | undefined;
  playerId: string | undefined;
  r: AudioRaffle | undefined;
}

export function getFirstPartyCookieValues(): CookieData | undefined {
  const parsedCookies = parseCookies();
  const firstPartyCookie = parsedCookies?.adu
    ? (JSON.parse(parsedCookies.adu) as CookieType)
    : undefined;

  if (!firstPartyCookie) {
    return undefined;
  }

  const {
    x: gender,
    y: age,
    l: plz,
    playerId,
    r: audioRaffle,
  } = firstPartyCookie;

  return {
    gender,
    age,
    plz,
    playerId,
    audioRaffle,
  };
}

export const getAgeRangeAlias = (age: number): ageRangeAlias => {
  if (age <= 17) {
    return 'y0';
  }

  if (age >= 18 && age <= 24) {
    return 'y1';
  }

  if (age >= 25 && age <= 34) {
    return 'y2';
  }

  if (age >= 35 && age <= 44) {
    return 'y3';
  }

  if (age >= 45 && age <= 54) {
    return 'y4';
  }

  return 'y5';
};

export const getGenderAlias = (gender: 'male' | 'female' | 'none') => {
  if (gender === 'male') {
    return 'x1';
  }

  if (gender === 'female') {
    return 'x2';
  }

  if (gender === 'none') {
    return 'x3';
  }

  return undefined;
};

export const convertBirthdateToAge = (date: string) => {
  const birthDate = parse(date, 'dd.MM.yyyy', new Date());

  return differenceInYears(new Date(), birthDate);
};

export function addFirstPartyCookieParamsToUrl(url: URL, values: CookieData) {
  const { gender, age, plz, playerId, audioRaffle } = values;

  if (gender) {
    url.searchParams.append('aw_0_1st.x', gender);
  }

  if (age) {
    url.searchParams.append('aw_0_1st.y', age);
  }

  if (plz) {
    url.searchParams.append('aw_0_1st.l', plz);
  }

  if (playerId) {
    url.searchParams.append('aw_0_1st.playerId', playerId);
  }

  if (audioRaffle) {
    url.searchParams.append('aw_0_1st.r', audioRaffle);
  }

  return url;
}
