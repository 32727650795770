import cls from 'classnames';
import { forwardRef } from 'react';
import ReactPlayer from 'react-player/lazy';

import ConsentWrapper from '@/components/one-trust/consent-wrapper';
import { OneTrust } from '@/types/apis/one-trust';
import { ExternalVideo, InternalVideo } from '@/types/views/generic';

import JWPlayerContainer from './jw-player-container';
import styles from './styles';
import VideoInfo from './video-info';

interface VideoProps {
  video: InternalVideo | ExternalVideo;
  shouldShowVideoInfo?: boolean;
  className?: string;
  onPlay?: () => void;
  onPause?: () => void;
  autoPlay?: boolean;
  hasAd?: boolean;
  isVideoInView?: boolean;
  toggleIsVideoMuted?: (isMuted?: boolean) => void;
  isUnMutedSection?: boolean;
  isGlobalRadioPlaying?: boolean;
  internalAutoPlayCheck?: boolean;
  prerollAdIndex?: number;
}

export const isInternalVideo = (
  entry: InternalVideo | ExternalVideo,
): entry is InternalVideo => (entry as InternalVideo).title !== undefined;

const Video = forwardRef<HTMLDivElement, VideoProps>(
  (
    {
      video,
      shouldShowVideoInfo = true,
      className = '',
      onPlay,
      onPause,
      autoPlay = false,
      hasAd = false,
      isVideoInView = true,
      toggleIsVideoMuted = () => null,
      isUnMutedSection = false,
      isGlobalRadioPlaying = false,
      internalAutoPlayCheck = true,
      prerollAdIndex,
    },
    ref,
  ) => (
    <div className={cls(className, styles.wrapper)}>
      <div ref={ref} data-id={(video as InternalVideo).taggingToolId}>
        {isInternalVideo(video) ? (
          <JWPlayerContainer
            video={video}
            autoPlay={autoPlay}
            hasAd={hasAd}
            onPlay={onPlay}
            onPause={onPause}
            isVideoInView={isVideoInView}
            toggleIsVideoMuted={toggleIsVideoMuted}
            isUnMutedSection={isUnMutedSection}
            isGlobalRadioPlaying={isGlobalRadioPlaying}
            internalAutoPlayCheck={internalAutoPlayCheck}
            prerollAdIndex={prerollAdIndex}
          />
        ) : (
          <div className="aspect aspect-2-3 md:aspect-16-9">
            <ConsentWrapper requiredGroups={[OneTrust.ConsentGroups.Targeting]}>
              <div className="bg-black-light">
                <ReactPlayer
                  url={video.url}
                  controls
                  width="100%"
                  height="100%"
                  onPlay={onPlay}
                  onPause={onPause}
                  playing={autoPlay}
                />
              </div>
            </ConsentWrapper>
          </div>
        )}
      </div>

      {shouldShowVideoInfo && isInternalVideo(video) && (
        <VideoInfo video={video} className={styles.videoInfo} />
      )}
    </div>
  ),
);

export default Video;
